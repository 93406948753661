import req from "../methods/req"
import method from '../methods/method'


//manage/crm.report/setReportMenuList

// 客户报表列表
export const setReportMenuList = function (data){
    return req({
        url:'/manage/crm.report/setReportMenuList',
        ...data
    })
}

//发放列表
export const investmentList = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.approval/investmentList',
        data
    })
}


export const getFreezer = function (data) {
	return method.get_list({
		url: '/manage/crm.freezer/getFreezer',
		...data,
	})
}

// 自定义导出预览列表--不分
export const getList2 = function (data) {
	return req({
		url: '/manage/crm.custom_export/getList',
		...data,
	})
}



// 自定义导出预览列表
export const getList = function (data) {
	return method.get_list({
		url: '/manage/crm.custom_export/getList',
		...data,
	})
}

/* 
 导出表格
 实际上导出并不通过本接口进行处理
 由于数据体积过大，因此该接口仅作提示作用
 告知用户表格已经进入导出队列，请到“运营管理/导出下载”页面查看导出状况或者进行下载
*/
export const exportTable = function (data) {
	return req({
		url: '/manage/crm.custom_export/export',
		...data,
	})
}

