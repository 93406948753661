<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['sort', 'status', 'operation']"
            :is_page="false"
            ref="list"
        >
            <template slot="sort" slot-scope="data">
                <a-input
                    type="text"
					style="max-width: 300px"
                    :value="data.text"
                    @change="e => handleChange(e.target.value,data,'sort')"
					@keyup.enter="e => handleEnter(e.target.value,data,'sort')"
                />
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text == 1">是</span>
                <span v-else>否</span>
            </template>
            <template slot="operation" slot-scope="data">
                <a v-if="data.record.status == 1" @click="changeShow(data)"
                    >隐藏</a
                >
                <a v-else @click="changeShow(data)">显示</a>
            </template>
        </TableList>
    </div>
</template>

<script>

import TableList from "@/components/TableList";
import { setReportMenuList } from '@/api/re'
import { setReportMenuList2, setReportMenu, setReportSort } from '@/api/goods'
const columns = [
    {
        title: "排序",
        dataIndex: "sort",
        scopedSlots: {
            customRender: "sort"
        }
    },
    {
        title: "报表名称",
        scopedSlots: {
            customRender: "title"
        },
        dataIndex: "title",
    },
    {
        title: "是否显示在App端",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        },

    },
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];



export default {
    name: "Index",
    components: {
        TableList
    },
    data() {
        return {
            get_table_list: setReportMenuList2,
            columns,


        };
    },

    methods: {

        handleChange(value,{text,record,index},key) {
			let list = [...this.$refs.list.list];
			list[index][key] = value;
			this.$refs.list.set_data('list',list)
		},
		handleEnter(value,{text,record,index},key){
			let obj = {
                id: record.id,
                sort: text,
            }
            setReportSort(obj).then(res => {
                this.$refs.list.get_list()
            })
		},

        changeShow(data) {
            let status = ''
            if (data.record.status) {
                status = 0
            } else {
                status = 1
            }
            let obj = {
                id: data.record.id,
                status: status,
            }
            setReportMenu(obj).then(res => {
                this.$refs.list.get_list()
            })
        },
        submit(e) {
            // if(!e.values.id){
            // 	e.values.id = '';
            // }
            //          changeCustomerLevel({
            //              data: e.values,
            //              info: true,
            //          }).then(res=>{
            // 	this.visible = false;
            // 	this.$refs.list.get_list();
            // })
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>